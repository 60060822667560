import { FcLike } from "react-icons/fc";

function App() {
  return (
    <>
      <h1 className="text-3xl font-bold underline">
        <FcLike />
        Hello World!
      </h1>
    </>
  );
}

export default App;
